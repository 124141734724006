export const crtIdatenMsg = () => {
  const isPracticePageSeen =
    localStorage.getItem('isPracticePageSeen') === 'done';

  // 練習ページを見ていた場合、言うことは昇級挑めってことくらい。
  if (isPracticePageSeen) {
    return '練習動画は見てくれたかな？おすすめ練習も十分にできたら、昇級テストに挑戦しよう！<<paragraph>>「トレーニング」のページで昇級試験を確認して、まずは動画を撮影しよう！<<paragraph>>動画の撮影が完了したら次は、＋ボタン→「昇級に挑戦」のページから動画を送ろう！';
  }

  return '何か困ったことがあったらここからはぜひコーチに聞いてみよう！<<paragraph>>右下の「サポート」は読んだかな？困ったことはLINEでお気軽にコーチに聞いてみてね！';
};

export const crtIdatenDefault = (onboardState: string) => {
  console.log(onboardState);
  if (!onboardState) {
    return '何をするか迷ったらボクの顔をタップしてみてね！ボクにわからないことは「サポート」ページを見てみてね！';
  }
  if (onboardState === 'beforeMsg') {
    return 'まずは宝箱をおして何が入っているか見てみよう！';
  }
  if (onboardState === 'beforePractice') {
    return 'そうしたらさっそく練習だ！きいろいボタンをタップしてみてね！';
  }
  if (onboardState === 'beforeChallenge') {
    return '練習はできたかな？十分に練習ができたら昇級テストにチャレンジしてみよう！';
  }
  if (onboardState === 'beforeLetter') {
    return '昇級テストの動画は月曜日にコーチが見てくれるよ！待ってる間は虫メガネを押して自分で自分の動きを見てみよう！';
  }
  if (onboardState === 'beforeRead') {
    return 'コーチからのコメントがかえって来ているよ！下のお手紙マークを押して昇級テストの結果とコメントを見てみよう！！';
  }
  return '何をするか迷ったらボクの顔をタップしてみてね！ボクにわからないことは「サポート」ページを見てみてね！';
};
