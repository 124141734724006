import React, { FC, useState } from 'react';
import { Button, CircularProgress, MenuItem, Select } from '@material-ui/core';
import { VideoType } from 'adapters/repositories/typeDefinition';

import { postManualScore, putRunnerInfo } from 'v1apiRequest';
import {
  ScoreEditType,
  checkPointListJA,
  grades,
} from 'components/MenuConst/GradeGoalConst';
import { optionsForEachCkpt } from 'components/MenuConst/optionsForEachCheckPoint/CheckpointOptions';
import { convertGradeByRunner, is10GradesPackage } from 'components/constants';
import {
  gradeTargetDict_20,
  nonFukushuGrades,
} from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

type Props = {
  score: ScoreEditType;
  comment: string;
  chosenVideo: VideoType;
  //eslint-disable-next-line
  checkByBoss?: boolean;
  //eslint-disable-next-line
  completeFunc?: () => void;
};

export const ScoreSendButton: FC<Props> = ({
  score,
  comment,
  chosenVideo,
  checkByBoss = false,
  completeFunc = () => null,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [weakPoint, setWeakPoint] = useState<grades | undefined>(
    chosenVideo.upload_runner.weak_point,
  );
  const options = optionsForEachCkpt(
    chosenVideo.upload_runner,
    chosenVideo.grade_before,
  );

  //10級パックを想定
  const checkpointList = Object.keys(options) as checkPointListJA[];

  const handleScoreSend = () => {
    const isNoValidation = [...checkpointList].every(
      (keyName: checkPointListJA) => !!score[keyName],
    );
    if (!score.eval) {
      alert('合否が未入力です');
    }
    const upOrStay = score.eval === '◯合格' ? 'up' : 'stay';

    // 何級のメニューを採点したかの情報をscoreに持たせる
    let scoredGrade = convertGradeByRunner(
      chosenVideo.upload_runner,
      chosenVideo.grade_before,
    );
    if (!nonFukushuGrades.includes(scoredGrade)) {
      scoredGrade = chosenVideo.upload_runner.weak_point ?? '20';
    }
    const scoreInfoToSend = { ...score, scoredGrade };

    if (isNoValidation) {
      const obj = {
        video_id: chosenVideo.id,
        account_id: chosenVideo.uploaded_by.id,
        runner_id: chosenVideo.upload_runner.id,
        file: chosenVideo.file,
        up_or_stay: upOrStay,
        check_by_boss: checkByBoss ? 'True' : '',
        details: JSON.stringify(scoreInfoToSend),
        coach_comment: comment,
      };
      setIsUploading(true);
      postManualScore(obj)
        .then((_) => {
          setIsUploading(false);
          completeFunc();
        })
        .catch((_) => {
          setIsUploading(false);
        });
      putRunnerInfo({
        id: chosenVideo.upload_runner.id,
        weak_point: weakPoint,
      });
    } else alert('1~5で入力してください');
  };

  const isRunnerWithWeakPoint =
    is10GradesPackage(chosenVideo.upload_runner) ||
    !!weakPoint ||
    !!chosenVideo.upload_runner.weak_point;

  const canSend =
    !!comment &&
    comment.length > 0 &&
    chosenVideo.is_exam &&
    isRunnerWithWeakPoint;

  return (
    <>
      <div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={weakPoint}
          onChange={(e) => {
            setWeakPoint(e.target.value as grades);
          }}
        >
          {nonFukushuGrades.map((g) => (
            <MenuItem value={g}>
              {g}級:{gradeTargetDict_20[g]}
            </MenuItem>
          ))}
        </Select>
      </div>
      {isUploading ? (
        <Button variant="contained" disabled style={{ marginTop: '20px' }}>
          採点結果送信 <CircularProgress size={20} />
        </Button>
      ) : (
        (!!canSend && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: '30px' }}
            onClick={handleScoreSend}
          >
            採点結果送信
          </Button>
        )) || (
          <Button variant="contained" disabled style={{ marginTop: '30px' }}>
            採点結果送信
          </Button>
        )
      )}
    </>
  );
};
