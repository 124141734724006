import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { grades, grades10 } from '../GradeGoalConst';

export const nonFukushuGrades: grades[] = [
  '20',
  '19',
  '18',
  '16',
  '15',
  '13',
  '12',
  '11',
  '9',
  '8',
  '7',
  '5',
  '4',
  '3',
  '2',
  '1',
  '0',
];

export const calcTarget = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    const grade = convertRunnerGrade(runner) as grades10;
    return gradeTargetDict_10[grade];
  }
  const grade = convertRunnerGrade(runner) as grades;
  if (!nonFukushuGrades.includes(grade)) {
    return (
      (!!runner.weak_point && gradeTargetDict_20[runner.weak_point]) ||
      'Y字スプリント'
    );
  }
  return gradeTargetDict_20[grade];
};

type gradeTargetDict10Type = {
  [key in grades10]: string;
};
export const gradeTargetDict_10: gradeTargetDict10Type = {
  10: 'Y字スプリント', //外
  9: '壁押しドリル', //家
  8: '3歩ハイニー', // 家
  7: '30mダッシュ', //外
  6: '自転車ドリル',
  5: '3ステップ',
  4: '30mダッシュ', // 外
  3: 'スタートの構え', // 外
  2: '壁押し上級', // 家
  1: 'スタートダッシュ', // 外
};

type gradeTargetDict20Type = {
  [key in grades]: string;
};
export const gradeTargetDict_20: gradeTargetDict20Type = {
  20: 'Y字スプリント', //外
  19: '壁押しドリル', //家
  18: '3歩ハイニー', // 家
  17: '今までの復習', //外
  16: 'せまめのマーク走', //外
  15: '30mダッシュ', //外
  14: '今までの総復習！', // 外
  13: 'スタートの構え', // 外
  12: 'かべおし上級', // 家
  11: 'スタートダッシュ', // 外
  10: '基礎編の復習', //外
  9: '自転車ダッシュ', //外
  8: '3ステップ', // 外
  7: 'マーク走', //外
  6: 'ふくしゅうのしま',
  5: 'かべおし3歩ハイニー',
  4: 'バネのようにはずめ！', // 外
  3: 'かけっこ診断70点！', // 外
  2: 'スタートダッシュ', // 家
  1: 'かけっこ診断75点！', // 外、ここまで変更
  0: '体を強くする',
};
