import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

////
//up
////
export const singleLegUpVideos = [
  {
    link: 'https://www.youtube.com/embed/VwaoB18_7ms',
    title: '片足物ひろい',
    message: (
      <div className={classes.subtitle}>
        動画1:「片足物ひろいを両足とも10回」× 2セット（目安）を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/xLOrneQXn2s',
    title: 'スケータージャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「スケータージャンプ10回」× 3セット（目安）を行う！
        </div>
        ☆なれてきたらななめ前にとんでみよう！
      </div>
    ),
  },
];

//タオルももあげは13級より

////
//tech
////

export const kabeoshiAdeVideos = [

  {
    link: 'https://www.youtube.com/embed/67JZVwNB7rc',
    title: '連続壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「連続壁押しドリル」を特訓（目安5～8分）！！
        </div>
        ☆まずは正しい形でできる角度で練習しよう！
        <br />
        ⇒出来たら「連続壁押しドリル10歩」× 3セット行う！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（10歩程度うつっている動画）を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
        <br />
        2. 足の上がり ☆ふとももが90度あがっているかな？
        <br />
        3. 空中であしを入れかえる
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ3つができた子はチャレンジ！～
        </div>
        4. 「45度～60度」くらい倒してチャレンジしてみよう！
        <br />
        <br />
        ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
        <br />
        ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！これがうまくなるとスタートがとっても速くなるよ！
        <br />
        ☆このメニューはおうちの中でも出来るが、少し音がしてしまうので注意！
      </div>
    ),
  },
];

export const skipAdvVideos = [
  {
    link: 'https://www.youtube.com/embed/JlOvd9PoBtI',
    title: '3歩スキップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「3歩スキップ20m」× 3～4本（目安）を行う！
        </div>
        ☆少しリズムがむずかしいけど、チャレンジしてみてね！
      </div>
    ),
  },
];

////
//run
////

export const dash10m12thVideos = [
  {
    link: 'https://www.youtube.com/embed/f1xmOxjA6SA',
    title: '倒れこみダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「倒れこみダッシュ10m」× 5本（目安）を行う！
        </div>
        ☆腰が折れ曲がってないか保護者さんなどにチェックしてもらおう！
        <br />
        ☆おうちの近くにのぼり坂があったら、そこでやってみよう！！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「スタートから10mまでの走り方」を復習！！
        </div>
        ⇒「10mダッシュ」にたくさんチャレンジ！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          復習のポイント
        </div>
        1. （スタートの構え）前あしに体重をのせる
        ☆後ろ足のかかとはういているかな？前あしの膝が足首のだいたい真上にあるかな？
        <br />
        2. （スタートの構え）いい姿勢で体をたおす
        ☆横から見て上体と後ろ足がまっすぐかな？目線は3m先を見れているかな？
        <br />
        3. しっかり体を倒す
        ☆6歩以上体をたおして走ろう！あごをひいて、目線は5m先くらい！
        <br />
        ※のぼり坂で10mダッシュの練習をすると意識しやすいかも！近場にのぼり坂のある子は試してみてね！
        <br />
        4. 足を前に前に ☆連続かべおしのときと同じ！
        <br />
        5. 腕ふり ☆前に前にふろう！
      </div>
    ),
  },
];

//ばね人間は15級から

////
//family
////

//過去のvideoより


///////
//以下10級パッケージ
//////

export const kabeoshiSeqVideos = [
  {
    link: 'https://www.youtube.com/embed/usm_mhrZSd4',
    title: '壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「壁押しももあげ」を復習（目安2分）！！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ポイントおさらい
        </div>
        1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
        <br />
        2. 足の上がり ☆ふとももが90度あがっているかな？
        <br />
        3. 体をたおせているか ☆45度～60度くらい倒してチャレンジしてみよう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/67JZVwNB7rc',
    title: '連続壁押しドリル',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「連続壁押しドリル」を特訓（目安5～8分）！！
        </div>
        ☆まずは正しい形でできる角度で練習しよう！
        <br />
        ⇒出来たら「連続壁押しドリル10歩」× 3セット行う！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本（10歩程度うつっている動画）を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆よこからみてまっすぐかな？かかとはういてるかな？
        <br />
        2. 足の上がり ☆ふとももが90度あがっているかな？
        <br />
        3. 空中であしを入れかえる
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        4. 「45度～60度」くらい倒してチャレンジしてみよう！
        <br />
        <br />
        ☆まずは正しい形でできる角度で！角度浅めから練習しよう！
        <br />
        ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！これがうまくなるとスタートがとっても速くなるよ！
        <br />
        ☆このメニューはおうちの中でも出来るが、少し音がしてしまうので注意！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/a2XE9UnDs3g',
    title: '壁押しドリル注意点①',
    message: (
      <div className={classes.subtitle}>
        動画3:「壁押し」の注意ポイント1：あしを後ろにはね上げない！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/CVWlD11uW5U',
    title: '壁押しドリル注意点②',
    message: (
      <div className={classes.subtitle}>
        動画4:「壁押し」の注意ポイント2：かかとは浮かせる！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/M39McbpaKgs',
    title: '壁押しドリル注意点③',
    message: (
      <div className={classes.subtitle}>
        動画5:「壁押し」の注意ポイント3：足は最初と同じ位置に着地する！
      </div>
    ),
  },
];


export const practice50mVideos = [
  {
    link: 'https://www.youtube.com/embed/SbSr3_5aXTI',
    title: '1次加速の練習',
    message: (
      <div className={classes.subtitle}>
        動画1:「スタートから10mまでの走り方」を復習！！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/6HdnLynUPT4',
    title: '2次加速の練習',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「10mから20mまでの走り方」を学ぶ！！
        </div>
        ⇒「20mダッシュ」× 3本を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/lFQtglK4VFI',
    title: '中間走の走り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「20mいこうの走り方」を学ぶ！！
        </div>
        ⇒「30mダッシュ」× 3本を行う！
      </div>
    ),
  },
];
