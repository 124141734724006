import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertGradeByRunner, is10GradesPackage } from 'components/constants';
import { nonFukushuGrades } from '../GoalsForEachGrade/targetsForEachGrade';
import { grades, grades10 } from '../GradeGoalConst';
import { optionsFor10Grades } from './ten_grades_package/optionsFor10Grades';
import { optionsFor20Grades } from './twenty_grades_package/optionsFor20Grades';

export const optionsForEachCkpt = (runner: RunnerType, grade: number) => {
  if (is10GradesPackage(runner)) {
    const g = convertGradeByRunner(runner, grade) as grades10;
    return optionsFor10Grades[g];
  }

  // 以下、20級パッケージとして処理
  const g = convertGradeByRunner(runner, grade) as grades;
  //復習の級だった場合optionをweak_pointの級にする
  if (!nonFukushuGrades.includes(g)) {
    return optionsFor20Grades[runner.weak_point ?? '20'];
  }
  return optionsFor20Grades[g];
};
