import React, { FC, useEffect, memo } from 'react';
import { Card } from '@material-ui/core';

import {
  checkPointListJA,
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { VideoType } from 'adapters/repositories/typeDefinition';
import { ScoreInput } from '../parttimePages/components/ScoreInput';
import { autoComment } from 'pages/adminPage/autoComment/AutoComment';
import { optionsForEachCkpt } from 'components/MenuConst/optionsForEachCheckPoint/CheckpointOptions';

type onChangeScoreType = (
  key: string,
) => (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  chosenVideo: VideoType;
  onChangeScore: onChangeScoreType;
  score: ScoreEditType;
  setComment: React.Dispatch<React.SetStateAction<string>>;
};

export const ManualScoringField: FC<Props> = memo((props) => {
  const { chosenVideo, onChangeScore, score, setComment } = props;

  const options = optionsForEachCkpt(
    chosenVideo.upload_runner,
    chosenVideo.grade_before,
  );
  console.log(options);
  const checkpointList = Object.keys(options) as checkPointListJA[]; //集合として一致すれば良い？

  // 全部埋めたら勝手にコメントが入るフック :FIXME完全に全部ではない
  useEffect(() => {
    if (!chosenVideo) return;
    if (Object.keys(score).length >= checkpointList.length + 1) {
      const AIcomment = autoComment(chosenVideo, score);
      console.log(AIcomment);
      setComment(AIcomment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [score]);

  return (
    <div>
      <video
        controls
        width="90%"
        src={`https://idaten-compressed-video.s3.amazonaws.com/video/${chosenVideo.file}`}
        loop
      ></video>
      <>
        {!!chosenVideo.is_exam ? (
          <Card>
            {checkpointList.map((ckpt, _) => {
              return (
                <ScoreInput
                  label={ckpt}
                  currentValue={score[ckpt] || ''}
                  onChangeScore={onChangeScore(ckpt)}
                  options={options[ckpt]}
                />
              );
            })}

            <div style={{ padding: '10px' }}>今回の出来栄え：{score.eval}</div>
          </Card>
        ) : null}
      </>
    </div>
  );
});
