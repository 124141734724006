import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Typography } from '@material-ui/core';
import { LoginResponse, sendLoginInfo } from 'features/auth/api/login';
import { AuthContext } from 'contexts/AuthContext';
import { PageTitle } from 'components/common/PageTitle';
import joy_family from 'static/materials/joy_family.png';
import idaten_kids from 'static/materials/idaten_kids_logo.png';
import { usePasswordInput } from '../hooks/usePasswordInput';
import { HandleChangeAndSetState, LoginInfoType } from '../types';
import { AxiosError, AxiosResponse } from 'axios';
import { SendButton } from '../components/SendButton';

export const LoginPage = () => {
  const navigate = useNavigate();
  const [isUploading, setIsUploading] = React.useState(false);
  const [loginInfo, setLoginInfo] = React.useState<LoginInfoType>({
    email: '',
    password: '',
  });

  const onChangePassword: HandleChangeAndSetState = (e) => {
    setLoginInfo({ ...loginInfo, password: e.target.value });
  };

  const { setIsAuthenticated } = React.useContext(AuthContext);
  const { PasswordInput } = usePasswordInput(onChangePassword);

  const handleSendClick = () => {
    setIsUploading(true);
    sendLoginInfo(loginInfo)
      .then((res: AxiosResponse<LoginResponse>) => {
        console.log(res.data);
        localStorage.setItem('access', res.data.access);
        setIsAuthenticated(true);
        setIsUploading(false);

        alert('OK');
      })
      .catch((res: AxiosError) => {
        console.log(res);
        console.log(res.message);
        if (res.message === 'Network Error') {
          alert('申し訳ありません。サーバーエラーです');
        } else if (res.message.indexOf('400')) {
          alert('認証情報が正しくありません');
        }

        setIsUploading(false);
      });
  };

  return (
    <div
      style={{
        textAlign: 'center',
        overflowY: 'scroll',
      }}
    >
      <PageTitle title="ログインページ" />
      <div style={{ textAlign: 'center' }}>
        <img src={joy_family} alt="" width="80%"></img>
        <div>
          <img src={idaten_kids} alt="" width="80%"></img>
          <br />
          <span
            style={{ fontWeight: 'bold', fontSize: '12pt', color: '#424242' }}
          >
            かけっこを楽しく学ぼう！
          </span>
        </div>
      </div>
      <div>
        <TextField
          style={{ marginTop: '20px', width: '70%' }}
          value={loginInfo.email}
          label="email"
          onChange={(e) => {
            setLoginInfo({ ...loginInfo, email: e.target.value });
          }}
        />
      </div>
      <div style={{ marginTop: '20px' }}>{PasswordInput}</div>
      <SendButton isUploading={isUploading} onClickFunc={handleSendClick} />

      <div style={{ marginTop: '50px' }}>
        <Typography style={{ fontSize: '12pt' }}>
          アカウント未発行の方は
          <span className="textlink" onClick={() => navigate('/register')}>
            新規登録
          </span>
          へ
        </Typography>
      </div>
      <div style={{ marginTop: '30px' }}>
        <Typography style={{ fontSize: '9pt' }}>
          パスワードをお忘れの方は
          <span
            className="textlink"
            onClick={() => navigate('/reset_pw_first')}
          >
            パスワード再設定ページ
          </span>
          へ
        </Typography>
      </div>
    </div>
  );
};
