import { RunnerType } from 'adapters/repositories/typeDefinition';

export const shouldPay = (runner: RunnerType) => {
  // 特定のstudent_groupの人は無条件にOK
  if (runner.student_group === 'nas') {
    return false;
  }
  const isSubscriptionValid =
    !!runner.expiration_date && new Date(runner.expiration_date) > new Date();
  if (runner.grade > 0 && !isSubscriptionValid) {
    return true;
  }
  return false;
};
