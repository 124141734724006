import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { grades10, grades } from '../GradeGoalConst';

export const decideMessageVideoLink = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    const runnerGrade = convertRunnerGrade(runner) as grades10;
    return introVideoDictFor10Grades[runnerGrade];
  }
  const runnerGrade = convertRunnerGrade(runner) as grades;
  return introVideoDictFor20Grades[runnerGrade];
};

type sampleVideoDict10Type = {
  [key in grades10]: string;
};

export const introVideoDictFor10Grades: sampleVideoDict10Type = {
  10: 'https://www.youtube.com/embed/j681TiLRK5s',
  9: 'https://www.youtube.com/embed/BDGKhOTLb6c',
  8: 'https://www.youtube.com/embed/2rYT4Tt3Gvk',
  7: 'https://www.youtube.com/embed/KDRUp0TnCBI',
  6: 'https://www.youtube.com/embed/6of0Mt6Q53s',
  5: 'https://www.youtube.com/embed/6Gc0mYgVfwY',
  4: 'https://www.youtube.com/embed/nZppBE08D0o',
  3: 'https://www.youtube.com/embed/LP45AVeINE8',
  2: 'https://www.youtube.com/embed/seMKF5NZbJk',
  1: 'https://www.youtube.com/embed/aHFYkpRmMTo',
};

type sampleVideoDictType = {
  [key in grades]: string;
};

export const introVideoDictFor20Grades: sampleVideoDictType = {
  20: 'https://www.youtube.com/embed/Mxor_tTRzl0',
  19: 'https://www.youtube.com/embed/Q_mrWndGaRk',
  18: 'https://www.youtube.com/embed/WV98b-1XAV0',
  17: 'https://www.youtube.com/embed/YriFD4qJvmM',
  16: 'https://www.youtube.com/embed/6-GywvC9NUI',
  15: 'https://www.youtube.com/embed/NnB53c5rDsc',
  14: 'https://www.youtube.com/embed/cM5iQUFHwEU',
  13: 'https://www.youtube.com/embed/egM1_0-56vw',
  12: 'https://www.youtube.com/embed/bfP2vpTC8GE',
  11: 'https://www.youtube.com/embed/TkUXz0RyBYA',
  10: 'https://www.youtube.com/embed/qcHs9HDCT2I',
  9: 'https://www.youtube.com/embed/lkG3Igem5Kg',
  8: 'https://www.youtube.com/embed/K-2vZCMiVxo',
  7: 'https://www.youtube.com/embed/ASQx2TKidL8',
  6: 'https://www.youtube.com/embed/jw73nT2BCk8',
  5: 'https://www.youtube.com/embed/W5eiDdO7IU8',
  4: 'https://www.youtube.com/embed/19LXxn0u5ts',
  3: 'https://www.youtube.com/embed/H68kaIwoSCE',
  2: 'https://www.youtube.com/embed/5cqL7begsdc',
  1: 'https://www.youtube.com/embed/7W2Tap6YnPk',
  0: 'https://www.youtube.com/embed/nizldYtCbx0',
};
