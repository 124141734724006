import React, { FC } from 'react';

import { VideoType, RunnerType } from 'adapters/repositories/typeDefinition';
import { TrainingMenuViewer } from 'pages/FiftyResultPage/components/TrainingMenuAccordion';
import { AIScore, thresholdMapper } from 'components/MenuConst/thresholds';

//eslint-disable-next-line

type Props = {
  video: VideoType;
  runner: RunnerType;
};

export const SuggestMenu: FC<Props> = ({ video, runner }) => {
  const ai_eval = JSON.parse(
    (!!video?.score?.ai_eval && video.score.ai_eval) || '',
  ) as AIScore;

  const postureScore = thresholdMapper('posture', ai_eval);
  const legScore = thresholdMapper('legScore', ai_eval);
  const armScore = thresholdMapper('armSwing', ai_eval);

  const yobina = crtYobina(runner);

  return (
    <div style={{ marginTop: '12px' }}>
      <TrainingMenuViewer
        posture={postureScore}
        arm={armScore}
        leg={legScore}
        yobina={yobina}
      />
      <div style={{ marginTop: '30px' }}>
        <br />
      </div>
    </div>
  );
};

export const crtYobina = (runner: RunnerType) => {
  if (!!runner.gender && !!runner.username) {
    const keisyo =
      (runner.gender === '男性' && 'くん') ||
      (runner.gender === '女性' && 'ちゃん') ||
      'さん';
    return String(runner.username + keisyo);
  }
  return 'きみ';
};
