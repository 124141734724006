import React, { FC } from 'react';
import { FirstView } from './FirstView';
import { SecondView } from './SecondView';
import { ThirdView } from './ThirdView';
import { FourthView } from './FourthView';
import { FifthView } from './FifthView';
import { SixthView } from './SixthView';
import { SeventhView } from './SeventhView';
import { EighthView } from './EighthView';
import { NinthView } from './NinthView';

import classes from './onboardingCarousel.module.css';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import {
  NextButton,
  NextAndBackButton,
  FinalButton,
} from 'hooks/PracticeOnboarding/components/NextButton';
import { ProgressBar } from './ProgressBar';

type Props = {
  handleClose: () => void;
  runner: RunnerType;
};

export const OnboardingCarousel: FC<Props> = ({ handleClose, runner }) => {
  const [cellNum, setCellNum] = React.useState<number>(0);
  const progress = Math.ceil((cellNum / 8) * 100);

  return (
    <div>
      <div>
        <div
          style={{
            textAlign: 'center',
            height: '52%',
          }}
        >
          <div style={{ width: '88%', marginLeft: '5%', marginBottom: '30px' }}>
            <ProgressBar progress={progress} />
          </div>

          {cellNum === 0 && (
            <>
              <div className={classes.modalContentWrapper}>
                <FirstView runner={runner} />
              </div>
              <div style={{ margin: '8px', textAlign: 'center' }}>
                <NextButton handleClick={() => setCellNum(cellNum + 1)} />
              </div>
            </>
          )}
          {cellNum === 1 && (
            <>
              <div className={classes.modalContentWrapper}>
                <SecondView runner={runner} />
              </div>
            </>
          )}
          {cellNum === 2 && (
            <>
              <div className={classes.modalContentWrapper}>
                <ThirdView runner={runner} />
              </div>
            </>
          )}
          {cellNum === 3 && (
            <>
              <div className={classes.modalContentWrapper}>
                <FourthView />
              </div>
            </>
          )}
          {cellNum === 4 && (
            <>
              <div className={classes.modalContentWrapper}>
                <FifthView />
              </div>
            </>
          )}
          {cellNum === 5 && (
            <>
              <div className={classes.modalContentWrapper}>
                <SixthView />
              </div>
            </>
          )}
          {cellNum === 6 && (
            <>
              <div className={classes.modalContentWrapper}>
                <SeventhView />
              </div>
            </>
          )}
          {cellNum === 7 && (
            <>
              <div className={classes.modalContentWrapper}>
                <EighthView />
              </div>
            </>
          )}
          {cellNum === 8 && (
            <>
              <div className={classes.modalContentWrapper}>
                <NinthView />
              </div>
              <div style={{ margin: '8px', textAlign: 'center' }}>
                <FinalButton
                  message="たのしむ！"
                  handleFinalClick={handleClose}
                  handleBackClick={() => setCellNum(cellNum - 1)}
                />
              </div>
            </>
          )}
          {cellNum > 0 && cellNum < 8 && (
            <div style={{ margin: '8px', textAlign: 'center' }}>
              <NextAndBackButton
                handleForwardClick={() => setCellNum(cellNum + 1)}
                handleBackClick={() => setCellNum(cellNum - 1)}
              />
            </div>
          )}
        </div>
        <div>
          <br />
        </div>
      </div>
    </div>
  );
};
