import {
  InputAdornment,
  IconButton,
  Input,
  InputLabel,
  FormControl,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import React, { FC, useState } from 'react';
import { HandleChangeAndSetState, HandleChangeType } from '../types';

export const usePasswordInput = (onChangePassword: HandleChangeAndSetState) => {
  const [password, setPassword] = useState<string>('');
  const handleChange = (e: HandleChangeType) => {
    setPassword(e.target.value);
    onChangePassword(e);
  };

  return {
    password: password,
    PasswordInput: (
      <PasswordInput password={password} handleChange={handleChange} />
    ),
  };
};

type PasswordInputProps = {
  password: string;
  handleChange: (e: HandleChangeType) => void;
};
const PasswordInput: FC<PasswordInputProps> = ({ password, handleChange }) => {
  const [show, setShow] = useState<boolean>(false);
  return (
    <FormControl style={{ width: '70%' }}>
      <InputLabel htmlFor="standard-adornment-password">パスワード</InputLabel>
      <Input
        id="standard-adornment-password"
        value={password}
        type={show ? 'text' : 'password'}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => {
                setShow(!show);
              }}
              onMouseDown={(e) => {
                e.preventDefault();
              }}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};
