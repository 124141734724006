import React from 'react';
import classes from '../purchasepage.module.css';
import { Button } from '@material-ui/core';
// import { useNavigate } from 'react-router-dom';

export const ApplyExplanation = ({ isSmartPhone = true }) => {
  //   const navigate =  useNavigate();

  return (
    <div style={{ textAlign: 'center', padding: '8px' }}>
      {isSmartPhone ? (
        <>
          <div className={classes.title}>お申し込み</div>
          <div style={{ textAlign: 'center', padding: '12px' }}>
            本プログラムへのご参加は、通常 <br />
            <div
              style={{
                margin: '6px',
                fontWeight: 'bold',
                color: '#424242',
                fontSize: '12pt',
              }}
            >
              <span style={{ fontSize: '16pt' }}>¥2,980</span>{' '}
              /月（税込3,278円）
            </div>
            となりますが
          </div>
        </>
      ) : (
        <>
          <div className={classes.title} style={{ marginTop: '40px' }}>
            お申し込み
          </div>
          <div style={{ textAlign: 'center', padding: '12px' }}>
            本プログラムへのご参加は、通常 <br />
            <div
              style={{
                margin: '6px',
                fontWeight: 'bold',
                color: '#424242',
                fontSize: '14pt',
              }}
            >
              <span style={{ fontSize: '18pt' }}>¥2,980</span>{' '}
              /月（税込3,278円）
            </div>
            となりますが
          </div>
        </>
      )}
      <div className={classes.semititle} style={{ marginTop: '10px' }}>
        <span
          style={{
            margin: '6px',
            fontWeight: 'bold',
            color: '#dc143c',
            fontSize: '18pt',
          }}
        >
          今なら 無料でお試しが可能！
        </span>
      </div>
      <div style={{ margin: '20px', marginBottom: '40px' }}>
        <Button
          variant="contained"
          size="large"
          style={{
            backgroundColor: '#1e90ff',
            fontWeight: 'bold',
            color: 'white',
          }}
        >
          <a
            href="https://www.idaten-app.com/register"
            target="_blank"
            rel="noreferrer"
            style={{ color: 'white', textDecoration: 'none' }}
          >
            ここをクリックして
            <br />
            無料トライアルに申し込む！
          </a>
        </Button>
      </div>
      <div style={{ borderRadius: '20px', backgroundColor: '#ffefd5' }}>
        <br />
        <div style={{ color: '#0000cd', fontWeight: 'bold', fontSize: '14pt' }}>
          ご利用までの流れは超簡単2ステップ！
          <br />
          {isSmartPhone ? (
            <div
              style={{
                textAlign: 'left',
                marginLeft: '10px',
                fontSize: '12pt',
                marginTop: '1px',
                color: '#424242',
              }}
            >
              <ol>
                <li>アカウントのご登録（約2分）</li>
                <li style={{ marginTop: '6px' }}>
                  専用アプリにログインしてさっそく練習開始！
                </li>
              </ol>
            </div>
          ) : (
            <div
              style={{
                textAlign: 'left',
                marginLeft: '10px',
                fontSize: '14pt',
                marginTop: '1px',
                color: '#424242',
                display: 'inline-block',
              }}
            >
              <ol>
                <li>&nbsp;アカウントのご登録（約2分）</li>
                <li style={{ marginTop: '6px' }}>
                  &nbsp;専用アプリにログインしてさっそく練習開始！
                </li>
              </ol>
            </div>
          )}
        </div>
        <div style={{ color: '#0000cd', fontWeight: 'bold', fontSize: '14pt' }}>
          〜無料でもできることは盛り沢山！〜
        </div>
        <div
          style={{
            fontSize: '12pt',
            fontWeight: 'bold',
            color: '#424242',
            marginTop: '8px',
          }}
        >
          ☆ 練習動画を10本ほど見られる！ <br />
          <span style={{ fontSize: '10pt', fontWeight: 'normal' }}>
            走る時やスタートの姿勢をわかりやすく解説！
          </span>
        </div>
        <div
          style={{
            fontSize: '12pt',
            fontWeight: 'bold',
            color: '#424242',
            marginTop: '8px',
          }}
        >
          ☆ プロコーチのからの添削を一回受けられる！ <br />
          <span style={{ fontSize: '10pt', fontWeight: 'normal' }}>
            コーチからのコメントを体験してみよう！
          </span>
        </div>

        <div
          style={{
            fontSize: '12pt',
            fontWeight: 'bold',
            color: '#424242',
            marginTop: '8px',
          }}
        >
          ☆ 50mトライアルにチャレンジ！ <br />
          <span style={{ fontSize: '10pt', fontWeight: 'normal' }}>
            お家の前で走るだけで、専用AIが50mタイムを予測！
          </span>
        </div>
        <div>
          <br />
          <div style={{ marginLeft: '70%' }}>etc・・・</div>
          <br />
        </div>
      </div>
    </div>
  );
};
