import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  postDjoserRegister,
  sendRegisterInfo,
} from 'features/auth/api/register';
import { handleRegisterError, networkError } from './repository';
import {
  checkIsFormComplete,
  crtValidateMessage,
  ValidationMsgType,
} from './ErrorCheckLogic';
import { useWindowSize } from 'hooks/useWindowSize';
import { SmartPhoneRegisterView } from './SmartPhoneRegisterView';
import { PCRegisterView } from './PCRegisterView';
import { AccountInfoType, infoKeys } from 'features/auth/types';
import { TEST_FRONT_DOMAIN } from 'v1apiRequest';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (keyName: infoKeys) => (e: inputEvent) => void;
type heightChangeFunc = (
  event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
) => void;

export const RegisterPage = () => {
  const size = useWindowSize();
  const isSmartPhoneView = size[0] < 500 || size[1] / size[0] > 1.5;
  const navigate = useNavigate();
  const [accountInfo, setAccountInfo] = useState<AccountInfoType>({
    userName: '',
    email: '',
    password: '',
    confirmPassword: 'con',
    gender: '',
    height: 140,
    showPassword: false,
    birthYear: '',
    birthMonth: '',
    birthDate: '',
    student_group: 'normal',
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState<ValidationMsgType>(
    {
      userName: '',
      email: '',
      password: '',
      confirmPassword: '',
      gender: '',
      height: '',
    },
  );
  const [isFormComplete, setIsFormComplete] = React.useState(false);

  React.useEffect(() => {
    setIsFormComplete(checkIsFormComplete(accountInfo, validationMessage));
  }, [accountInfo, validationMessage]);

  const handleHeightChange: heightChangeFunc = (e) => {
    if (typeof e.target.value === 'number')
      setAccountInfo({ ...accountInfo, height: e.target.value });
  };

  const handleInfoChange: handleInputChange = (keyName: infoKeys) => (e) => {
    let _info = { ...accountInfo };
    _info[keyName] = e.target.value;
    setAccountInfo(_info);
    const validationMsg = crtValidateMessage(
      keyName,
      e.target.value,
      accountInfo,
      validationMessage,
    );

    setValidationMessage(validationMsg);
  };

  const handleSendClick = () => {
    const sendInfo = {
      ...accountInfo,
      ...{
        birthday: `${accountInfo.birthYear}-${accountInfo.birthMonth}-${accountInfo.birthDate}`,
        first_name: 'df',
        confirmPassword: accountInfo.password,
        app: 'midaten',
        app_version: 'test',
        domain: process.env.REACT_APP_FRONT_DOMAIN ?? TEST_FRONT_DOMAIN,
      },
    };
    console.log(sendInfo);
    setIsUploading(true);
    postDjoserRegister(sendInfo)
      .then((res) => {
        console.log(res.data);
        sendRegisterInfo(sendInfo).then((_res) => {
          setIsUploading(false);
          alert('認証メールを送信しました');
          navigate('/mail-sent');
        });
      })
      .catch((error: networkError) => {
        console.log(error);
        const alertMsg = handleRegisterError(error);
        setIsUploading(false);
        alert(alertMsg);
      });
  };

  const handleClickShowPassword = () => {
    setAccountInfo({
      ...accountInfo,
      showPassword: !accountInfo.showPassword,
    });
  };

  const gotoLogin = () => {
    navigate('/login');
  };

  if (!isSmartPhoneView) {
    return (
      <PCRegisterView
        accountInfo={accountInfo}
        handleInfoChange={handleInfoChange}
        handleHeightChange={handleHeightChange}
        validationMessage={validationMessage}
        handleClickShowPassword={handleClickShowPassword}
        isFormComplete={isFormComplete}
        isUploading={isUploading}
        goToLogin={gotoLogin}
        handleSendClick={handleSendClick}
      />
    );
  }

  return (
    <SmartPhoneRegisterView
      accountInfo={accountInfo}
      handleInfoChange={handleInfoChange}
      handleHeightChange={handleHeightChange}
      validationMessage={validationMessage}
      handleClickShowPassword={handleClickShowPassword}
      isFormComplete={isFormComplete}
      isUploading={isUploading}
      goToLogin={gotoLogin}
      handleSendClick={handleSendClick}
    />
  );
};
