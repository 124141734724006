import React, { useCallback } from 'react';
import { Button } from '@material-ui/core';
import classes from '../purchasepage.module.css';
import { useNavigate } from 'react-router-dom';

export const FixBarButton = () => {
  const navigate = useNavigate();

  const goToRegister = useCallback(() => {
    navigate('/register');
  }, []);

  //   const handleTrialClick = () => {
  //     navigate('/register');
  //   };
  //   return (
  //     <div className={classes.fixBar}>
  //       <div style={{ padding: '12px' }}>
  //         <Button
  //           color="primary"
  //           onClick={handleTrialClick}
  //           variant="contained"
  //           size="medium"
  //         >
  //           無料トライアルお申し込み
  //         </Button>
  //       </div>
  //     </div>
  //   );

  return (
    <div className={classes.fixBar}>
      <div style={{ padding: '12px' }}>
        <Button
          color="primary"
          variant="contained"
          size="medium"
          onClick={goToRegister}
        >
          まずは無料で登録する！
        </Button>
      </div>
    </div>
  );
};

const scrollToApplyForm = () => {
  const targetElement = document.getElementById('applyForm');

  // 画面上部から要素までの距離
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // スクロール位置に持たせるバッファ
  const buffer = -100;

  const top = rectTop + offsetTop - buffer;

  window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
};
