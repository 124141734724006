import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';


////
//run
////

export const skipBasicVideos = [
  {
    link: 'https://www.youtube.com/embed/xSFx-aErnDI',
    title: '上方向スキップ',
    message: (
      <div className={classes.subtitle}>
        動画1:「上方向スキップ10m」× 2本（目安）を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/fFd2h1hNSRs',
    title: '前方向スキップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「前方向スキップ20m」× 3本（目安）を行う！
        </div>
        ☆20mを何歩で行けるかチャレンジしてみよう！ポイントはうでも大きく使うこと！
        <br />
        ☆なれてきたら「前になるべく速く進むスキップ」や「前方向スキップ10m⇒そのまま15mダッシュ」にもチャレンジしてみよう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/VynfHOQ6CUc',
    title: '前方向に速いスキップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「前方向スキップ20m」× 3本（目安）を行う！
        </div>
        ☆「前方向スキップ10m⇒そのまま15mダッシュ」にもチャレンジしてみよう！
      </div>
    ),
  },
];

export const skaterJumpVideos = [
  {
    link: 'https://www.youtube.com/embed/xLOrneQXn2s',
    title: 'スケータージャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「スケータージャンプ10回」× 3セット（目安）を行う！
        </div>
        ☆なれてきたらななめ前にとんでみよう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/4w6vjwk3O3M',
    title: 'スケータージャンプからのダッシュ',
    message: (
      <div className={classes.subtitle}>
        動画2:「スケータージャンプからのダッシュ」にチャレンジしてみよう！
      </div>
    ),
  },
];

export const singleLegTrainingVideos = [
  {
    link: 'https://www.youtube.com/embed/o4uQYa_S2fk',
    title: '片足回転ジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「片足回転ジャンプ」を楽しみながらやってみる！
        </div>
        ☆けっこうしんどいトレーニングなので疲れすぎないようほどほどに！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/GzJNMzpECI4',
    title: '片足十字ケンケン',
    message: (
      <div className={classes.subtitle}>
        動画2:「片足十字ケンケン」を両足とも楽しみながらやってみる！
      </div>
    ),
  },
];


////
//family（かけっこ診断とスタート構えはgrade_0からとってくる）
////

export const singleLegPlayVideos = [
  {
    link: 'https://www.youtube.com/embed/VwaoB18_7ms',
    title: '片足物ひろい',
    message: (
      <div className={classes.subtitle}>
        動画1:「片足物ひろいを両足とも10回」× 2セット（目安）を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/watch?v=3oGeilSoHmE',
    title: '片足キャッチ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「片足キャッチ」を楽しみながらやってみよう！
        </div>
        ☆ぜひご家族で勝負形式でやってみてください！
      </div>
    ),
  },
];



/////////////////////////
//以下10級パッケージ用

export const dash30m4thVideos = [
  {
    link: 'https://www.youtube.com/embed/aVvoKl6RIgo',
    title: 'ベースポジション3歩ステップ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「ベースポジション3歩ステップ10m⇒そのまま15mダッシュ」を3～5本おこなう！！
        </div>
        ☆ベースポジション3歩ステップの時の「あしを体の前で入れかえる」感じのままダッシュしてみよう！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/KDRUp0TnCBI',
    title: '7級イントロ動画',
    message: (
      <div>
        （動画2）下の動画を見なおした上で、「30mダッシュ」にたくさんチャレンジ！
        <div style={{ color: 'red', fontWeight: 'bold', padding: '6px' }}>
          ☆昇級テストはこちら！「右下+ボタン⇒昇級に挑戦」から自信のある1本を送信！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          昇級のポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <br />
        ※むずかしい子は「Y字スプリント」を復習！
        <br />
        2. 足を体の前で回す ☆あしを体の前で入れかえることが出来ているかな？
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <br />
        4. うで振り ☆下にポンポンふろう！
        <br />
        5. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
      </div>
    ),
  },
];

export const cycleDashVideos = [
  {
    link: 'https://www.youtube.com/embed/ApkuMRS9CFU',
    title: '自転車ダッシュ',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画1）「自転車ダッシュ20m」× 3本を行う！！
        </div>
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ポイント
        </div>
        1. よい姿勢 ☆たおしすぎていないかな？あごを引いて15m先に地面を見よう！
        <br />
        2. 足を体の前で回す
        ☆足が自然とまえにもどってきて、自然とからだの前で回る感覚がつかめたらGood！
        <div
          style={{ textAlign: 'center', fontWeight: 'bold', padding: '6px' }}
        >
          ～うえ2つができた子はチャレンジ！～
        </div>
        3. 足のつき方 ☆ぼしきゅうからつけているかな？
        <br />
        4. スピードを出す
        ☆なれてきたらチャレンジ！思いっきり走る練習はとてもだいじ！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/7VuxJpha3sY',
    title: '自転車ダッシュ⇒腕おろして走る',
    message: (
      <div>
        <div className={classes.subtitle}>
          （動画2）「自転車ダッシュ15m⇒腕を下ろしてダッシュ10m」× 3本を行う！！
        </div>
        ☆腕を下ろした後もあしが自然と戻ってくる（あしが自然とからだの前で回る）感覚がつかめたらGood！
      </div>
    ),
  },
];
