import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useWindowSize } from 'hooks/useWindowSize';
import { SalesPoints } from './SalesPoints';
import { CustomerReview } from './CustomerReview';

import { ProgramExplanation } from './ProgramExplanation';
import { SkyBackgroundSummary } from './SkyBackgroundSummary';
import { PurchaseQA } from 'pages/UnmemberPages/components/PurchasePageQA';
import { Schedule } from './Schedule';
import { FixBarButton } from './FixBar';
import { ApplyExplanation } from './ApplyExplanation';
import { Inquiry } from './Inquiry';
import { AppliStrength } from './AppliStrength';
import { YouAreTarget } from './YouAreTarget';
import { TryFree } from './TryFree';
import { ReasonsWhy } from './ReasonsWhy';
import { PurchaseExplanation } from './PurchaseExplanation';

export const PurchasePageMobile = () => {
  const handleGoToApply = () => {
    scrollToApplyForm();
  };

  return (
    <div style={{ textAlign: 'center', fontFamily: 'Arial' }}>
      <ProgramExplanation />
      {/* <YouAreTarget /> */}

      <SkyBackgroundSummary />

      <div style={{ marginTop: '20px' }}>
        <AppliStrength />
      </div>

      <div style={{ marginTop: '20px' }}>
        <SalesPoints />
      </div>
      <CustomerReview />
      {/* <TryFree /> */}

      <div style={{ marginTop: '50px' }}>
        <Schedule />
      </div>
      {/* <div style={{ marginTop: '50px' }}>
        <CustomerReview />
      </div>  */}
      <div
        id="applyForm"
        style={{ backgroundColor: '#ffffff', marginTop: '50px' }}
      >
        <ApplyExplanation isSmartPhone={true} />
      </div>

      <div style={{ marginTop: '50px' }}>
        <PurchaseQA />
      </div>
      <div style={{ marginTop: '20px', width: '100%' }}>
        <Inquiry />
      </div>
      <FixBarButton />
    </div>
  );
};

type sizeType = number[];
export const calculateVideoScreen = (size: sizeType) => {
  return {
    width: Math.trunc(size[0] * 0.9),
    height: Math.trunc(size[0] * 0.6),
  };
};

const scrollToApplyForm = () => {
  const targetElement = document.getElementById('applyForm');

  // 画面上部から要素までの距離
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const rectTop = targetElement.getBoundingClientRect().top;
  // 現在のスクロール距離
  const offsetTop = window.pageYOffset;
  // スクロール位置に持たせるバッファ
  const buffer = 20;

  const top = rectTop + offsetTop - buffer;

  window.scrollTo({
    top: top,
    behavior: 'smooth',
  });
};
