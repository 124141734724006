import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { InputAdornment, IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  videoList: VideoType[];
  setVideoToShow: React.Dispatch<React.SetStateAction<VideoType[]>>;
};

type handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

export const SearchVideoBar: FC<Props> = ({ videoList, setVideoToShow }) => {
  const [searchWord, setSearchWord] = useState('');

  const handleSearchWordChange: handleInputChange = (e) => {
    setSearchWord(e.target.value);
  };
  const handleSearch = () => {
    if (!videoList) {
      return;
    }
    let _videos = videoList.filter((v) => {
      if (!v) {
        return false;
      }
      return v.upload_runner.username.indexOf(searchWord) !== -1;
    });
    setVideoToShow(_videos);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '80vw' }}>
        <TextField
          variant="outlined"
          label="ユーザーIDまたはビデオID"
          style={{ width: '95%', marginLeft: '10px' }}
          multiline
          value={searchWord}
          onChange={handleSearchWordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {' '}
                <IconButton onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div style={{ width: '10vw', padding: '10px' }}>
        <Button variant="outlined" onClick={handleSearch}>
          検索
        </Button>
      </div>
    </div>
  );
};
