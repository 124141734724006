import React from 'react';
import { RouteObject } from 'react-router-dom';
import { AuthRoutes } from 'features/auth/routes';
import { LoginPage } from 'features/auth/routes/LoginPage';
import { MailAuthenticationPage } from 'pages/auth/MailAuthenticationPage';
import { RegisterPage } from 'pages/auth/Register/RegisterPage';
import { ReLoginPage } from 'pages/auth/ReLoginPage';
import { AfterMailSentPage } from 'pages/auth/AfterMailSentPage';
import { PrefilledRegisterPage } from 'pages/auth/Register/PrefilledRegisterPage';
import { ResetPasswordFirstPage } from 'pages/auth/ResetPasswordFirstPage';
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage';
import { ResultSharePage } from 'pages/ResultPage/ResultSharePage';
import { FiftyResultPage } from 'pages/FiftyResultPage';
import { PurchasePage } from 'pages/PurchasePage';
import { NASExplanationPage } from 'pages/NASExplanationPage';
import { DiagnoseUploadPage } from 'pages/FiftyCheckLP/DiagnoseUploadPage';
import { AfterCheckoutPage } from 'pages/UnmemberPages/AfterCheckoutPage';
import { FiftyCheckLP } from 'pages/FiftyCheckLP/FiftyCheckLP';

//FIXME: ここがぐちゃぐちゃなので後でちゃんとする
export const routes: RouteObject[] = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
  {
    path: '/varify/:token/:uid',
    element: <MailAuthenticationPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
  },
  {
    path: '/register-prefilled/:videoId',
    element: <PrefilledRegisterPage />,
  },
  {
    path: '/relogin',
    element: <ReLoginPage />,
  },
  {
    path: '/mail-sent',
    element: <AfterMailSentPage />,
  },
  {
    path: '/reset_pw_first',
    element: <ResetPasswordFirstPage />,
  },
  {
    path: '/reset_pw/:token/:uid',
    element: <ResetPasswordPage />,
  },
  {
    path: '/result-share/:videoId',
    element: <ResultSharePage />,
  },
  {
    path: '/fifty_result/:videoId',
    element: <FiftyResultPage />,
  },
  {
    path: '/purchase',
    element: <PurchasePage />,
  },
  {
    path: '/explanation',
    element: <NASExplanationPage />,
  },
  {
    path: '/diagnose',
    element: <DiagnoseUploadPage />,
  },
  {
    path: '/after-checkout',
    element: <AfterCheckoutPage />,
  },
  {
    path: '/introduction',
    element: <FiftyCheckLP />,
  },

  {
    path: '*',
    element: <LoginPage />,
  },
];
