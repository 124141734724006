import { ckptDetails_2kyu_20 } from 'components/MenuConst/GoalsForEachGrade/CheckpointsFor20Pkg/2_kyu';

type options = {
  [key in ckptDetails_2kyu_20]: string[];
};

export const optionsFor2kyu_20pkg: options = {
  構えで前脚に体重がのっている: ['◯:ちょうど良い', '×:足首が膝より前'],
  構えで上体角度が45度程度: ['◯:45度程度', '×:倒しすぎ', '×:起きすぎ'],
  構えで横から見て上体と後ろ足がまっすぐ: [
    '◯:まっすぐ',
    '×:腰が引けている',
    '×:腰が反っている',
  ],
  六歩以上体を倒せている: [
    '◯:倒せている',
    '×:すぐ起きてしまっている',
    '◯:やや起きるのが早い',
  ],
  足の流れ: ['◯:出来ている', '×:後ろで大きく回っている'],
  足を壁押しのように動かす: [
    '◯:出来ている',
    '×:無理に体の前で漕ぐようにうごかしている',
  ],
  足の上がり: ['◯:90度程度上がっている（上げ過ぎは許容）', '×:上がっていない'],
  リーチアウト: [
    '◯:していない（足首が膝より前に出ていない）',
    '×:足が開いている',
  ],
  うでを前に前に振る: ['◯:前に振れている', '×:振れていない'],
};
