import React from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from 'contexts/AccountContext';

import { BasicInfoKids } from './BasicInfo';
import { getVideoForShareAdapter } from 'adapters/getVideosAdapter';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { ShareButton } from './components/ShareButton';
import { ShareModal } from './components/ShareModal';

type currentVideoType = VideoType | null;

export const ResultSharePage = () => {
  const { videoId } = useParams();
  console.log(videoId);

  const { currentRunner } = React.useContext(AccountContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const [currentVideo, setCurrentVideo] =
    React.useState<currentVideoType>(null);

  const isFirstLogin = videoId === 'first-grade' || videoId === 'sample';

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleClick = () => {
    const text =
      (!!currentVideo &&
        `https://idaten-app.com/result-share/${currentVideo.id}`) ||
      `https://idaten-app.com/result-share/sample`;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('クリップボードにコピーしました');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 動画のIDを元にビデオを持ってくる
  React.useEffect(() => {
    if (isFirstLogin) {
      return;
    }
    setIsLoading(true);
    getVideoForShareAdapter(videoId).then((video) => {
      console.log(video);
      setCurrentVideo(video[0]);
      setIsLoading(false);
    });
  }, [videoId]);

  return (
    <div className="App">
      {/* <PageTitle title={'成績表'} /> */}
      <ShareModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentVideo={currentVideo}
        onCopyClick={handleClick}
      />
      <div
        style={{
          marginTop: '6px',
          width: '98%',
          marginLeft: '1.0%',
          textAlign: 'center',
        }}
      >
        <BasicInfoKids
          isFirstLogin={isFirstLogin}
          isLoading={isLoading}
          video={currentVideo}
          runner={currentRunner}
        />
      </div>
      <ShareButton onClick={handleButtonClick} />

      <div style={{ marginTop: '100px' }}></div>
    </div>
  );
};
