import {
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';

import { crtComment10th } from './crtComment_10grades/crtCommentFor10th';
import { crtComment9th } from './crtComment_10grades/crtCommentFor9th';
import { crtComment8th } from './crtComment_10grades/crtCommentFor8th';
import { crtComment7th } from './crtComment_10grades/crtCommentFor7th';
import { crtComment6th } from './crtComment_10grades/crtCommentFor6th';
import { crtComment5th } from './crtComment_10grades/crtCommentFor5th';
import { crtComment4th } from './crtComment_10grades/crtCommentFor4th';
import { crtComment3rd } from './crtComment_10grades/crtCommentFor3rd';
import { crtComment2nd } from './crtComment_10grades/crtCommentFor2nd';
import { crtComment1st } from './crtComment_10grades/crtCommentFor1st';
import { convertGradeByRunner, is10GradesPackage } from 'components/constants';

import { autoCommentFor20thPkgs } from './crtComment_20grades/crtCommentFor20pkgs';

export const autoComment = (chosenVideo: VideoType, score: ScoreEditType) => {
  const runnerInfo = chosenVideo.upload_runner;
  const runnerName = runnerInfo.username;
  const keisyo = runnerInfo.gender === '男性' ? 'くん' : 'ちゃん';
  const yobina = runnerName + keisyo;
  let comment = '';

  if (is10GradesPackage(runnerInfo)) {
    if (chosenVideo.grade_before === 0) {
      comment = crtComment10th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 1) {
      comment = crtComment9th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 2) {
      comment = crtComment8th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 3) {
      comment = crtComment7th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 4) {
      comment = crtComment6th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 5) {
      comment = crtComment5th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 6) {
      comment = crtComment4th(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 7) {
      comment = crtComment3rd(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before === 8) {
      comment = crtComment2nd(runnerInfo, score, yobina);
    }
    if (chosenVideo.grade_before >= 9) {
      comment = crtComment1st(runnerInfo, score, yobina);
    }
    return comment + crt_line4(chosenVideo.video_memo);
  }

  // ここから20級パッケージ
  const kyuOfVideo = convertGradeByRunner(runnerInfo, chosenVideo.grade_before);
  comment = autoCommentFor20thPkgs(chosenVideo, score, kyuOfVideo);

  return comment;
};

function crt_line4(video_memo: string | undefined) {
  if (!video_memo) {
    return '来週からはよかったら質問してね！！';
  }

  return `質問してくれた「${video_memo}」という点ですが、`;
}
