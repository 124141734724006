import React from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from 'contexts/AccountContext';

import { BasicInfoKids } from 'pages/ResultPage/BasicInfo';
import { VideoNavigator } from 'pages/ResultPage/VideoChooseCarousel/VideoNavigator';
import { getVideoByIdAdapter } from 'adapters/getVideosAdapter';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { ShareButton } from 'pages/ResultPage/components/ShareButton';
import { ShareModal } from 'pages/ResultPage/components/ShareModal';

type currentVideoType = VideoType | null;

export const ResultPage = () => {
  const { videoId } = useParams();
  const { currentRunner } = React.useContext(AccountContext);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [currentVideo, setCurrentVideo] =
    React.useState<currentVideoType>(null);

  const isFirstLogin = videoId === 'first-grade';

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleClick = () => {
    const text =
      (!!currentVideo &&
        `https://idaten-app.com/result-share/${currentVideo.id}`) ||
      '';
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('クリップボードにコピーしました');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 動画のIDを元にビデオを持ってくる
  React.useEffect(() => {
    if (isFirstLogin || !videoId) {
      return;
    }
    setIsLoading(true);
    getVideoByIdAdapter(videoId).then((video) => {
      setCurrentVideo(video[0]);
      setIsLoading(false);
    });
  }, [videoId]);

  return (
    <div className="App">
      {/* <PageTitle title={'成績表'} /> */}
      <ShareModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        currentVideo={currentVideo}
        onCopyClick={handleClick}
      />
      <div
        style={{
          marginTop: '6px',
          width: '98%',
          marginLeft: '1.0%',
          textAlign: 'center',
        }}
      >
        <BasicInfoKids
          isFirstLogin={isFirstLogin}
          isLoading={isLoading}
          video={currentVideo}
          runner={currentRunner}
        />
      </div>
      <ShareButton onClick={handleButtonClick} />
      {(!!videoId && (
        <VideoNavigator videoId={videoId} setCurrentVideo={setCurrentVideo} />
      )) || <></>}
      <div style={{ marginTop: '100px' }}></div>
    </div>
  );
};
