import React, { FC } from 'react';
import { TextField, Grid } from '@material-ui/core';
import { VideoType } from 'adapters/repositories/typeDefinition';

import { ManualScoringField } from 'pages/adminPage/common/ManualScoringField';
import { ScoreEditType } from 'components/MenuConst/GradeGoalConst';
import { StudentCommentPart } from 'pages/adminPage/common/StudentCommentPart';
import { ScoreSendButton } from 'pages/adminPage/common/ScoreSendButton';
import { decideUpOrStay } from 'pages/adminPage/common/decideUpOrStay';
import { AIScore } from 'components/MenuConst/thresholds';
import { AIEvaluationPart } from 'pages/adminPage/common/AIEvaluationPart';

type handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

type handleScoreChange = (
  key: string,
) => (e: React.ChangeEvent<HTMLInputElement>) => void;

type Props = {
  chosenVideo: VideoType;
  fetchVideos: () => void;
};

export const ScoreEditField: FC<Props> = ({ chosenVideo, fetchVideos }) => {
  const [comment, setComment] = React.useState('');

  //eslint-disable-next-line
  const initialScore: ScoreEditType = chosenVideo.score?.coach_comment
    ? JSON.parse(chosenVideo.score.details)
    : ({} as ScoreEditType);

  const [score, setScore] = React.useState(initialScore);
  React.useEffect(() => {
    // すでにコメントがある場合
    if (!!chosenVideo.score?.coach_comment) {
      setComment((chosenVideo.score && chosenVideo.score.coach_comment) || '');
      return;
    }
    console.log(chosenVideo.score);
    setScore(initialScore);
    setComment((chosenVideo.score && chosenVideo.score.coach_comment) || '');
    const ai_eval =
      (!!chosenVideo?.score?.ai_eval &&
        (JSON.parse(chosenVideo.score.ai_eval) as AIScore)) ||
      '';
    console.log('ai_eval=', ai_eval);
  }, [chosenVideo]);

  const completeFunc = () => {
    alert('ok');
    fetchVideos();
  };

  const onChangeScore: handleScoreChange = (key) => (e) => {
    const nextScore = { ...score, [key]: e.target.value };
    const upOrStay = decideUpOrStay(nextScore, chosenVideo)
      ? '◯合格'
      : '×不合格';

    setScore({ ...score, [key]: e.target.value, eval: upOrStay });
  };

  const handleChangeComment: handleInputChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <>
      <Grid container style={{ marginTop: '20px' }}>
        <Grid item xs={6}>
          <ManualScoringField
            chosenVideo={chosenVideo}
            onChangeScore={onChangeScore}
            score={score}
            setComment={setComment}
          />

          <TextField
            variant="outlined"
            label="コメント"
            style={{ width: '100%', marginTop: '26px' }}
            multiline
            placeholder="（例）足がちょっと流れています"
            value={comment}
            onChange={handleChangeComment}
          />
          <ScoreSendButton
            score={score}
            comment={comment}
            chosenVideo={chosenVideo}
            checkByBoss={true}
            completeFunc={completeFunc}
          />
        </Grid>
        <Grid item xs={6} style={{ padding: '10px' }}>
          <StudentCommentPart chosenVideo={chosenVideo} />
          <AIEvaluationPart chosenVideo={chosenVideo} />
        </Grid>
      </Grid>
      <div style={{ marginTop: '100px' }}></div>
    </>
  );
};
